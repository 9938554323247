// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowRight, FaChartLine, FaCheck, FaEye, FaFacebook, FaInstagram, FaLightbulb, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearchLocation, FaSeedling, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdLocationPin, MdMenu, MdMenuOpen, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/home.css';
import '../Components/css/about.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';

const About = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMenu = (rota) => {
        setMenu(!menu)
        if (rota.length > 0) { navigate(rota) }
    }


    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8' style={{ maxWidth: '1400px' }}>
                        <img
                            src={require('../assets/logo/add-value.png')}
                            className='w-32'
                            onClick={() => navigate('/')}
                            alt='Ad Value Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                        <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Home</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/servicos')}>Serviços</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p  onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                            <Button className='press-button' onClick={() => window.open('https://www.oeconomico.com/')}>
                                Notícias
                            </Button>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner-about'>
                        <div className='banner-content px-8 sm:px-16' style={{ maxWidth: '1400px' }}>
                            <h1 className='banner-title'>SOBRE A <span className='bt-bold'>AD VALUE</span></h1>

                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Home</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/servicos')}>Produtos</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section-gr px-8 sm:px-16 py-10 mx-auto'>
                        <div className='section-in flex-col sm:flex-row gap-18'>
                            <div className='w-full sm:w-1/2 flex flex-col items-left gap-2'>
                                <img
                                    src={require('../assets/images/what-we-do.webp')}
                                    className='w-full rounded-lg'
                                    alt='Ad Value Quem Somos'
                                />
                            </div>
                            <div className='w-full sm:w-1/2 flex flex-col items-left justify-center gap-2'>
                                <p className='section-title'>Quem Somos</p>
                                <p className='section-text'>
                                    A Ad Value Comunicação Estratégica Limitada é uma empresa dedicada a fornecer soluções inovadoras e personalizadas no universo da comunicação
                                    estratégica. Trabalhamos de forma criativa e diferenciada para servir nossos clientes e parceiros, oferecendo soluções à medida e custo-efetivas
                                    que agregam valor a produtos, ideias, iniciativas e projetos.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='section-gr px-8 sm:px-16 py-10 pt-2 mx-auto'>
                        <div className='section-in flex-col sm:flex-row gap-18'>
                            <div className='w-full sm:w-1/2 flex flex-col items-left justify-center gap-2'>
                                <p className='section-title'>O Que Fazemos</p>
                                <p className='section-text'>
                                    Desenvolvemos e implementamos estratégias de comunicação e imagem, além de planos de ação integrados de comunicação e marketing institucional e
                                    estratégico. Atendemos instituições públicas e privadas, empresas privadas, instituições governamentais e ONGs. Nossa abordagem abrange desde a
                                    concepção do projeto de comunicação, imagem e marca, até a assessoria de imprensa e desenvolvimento de conteúdos de forma criativa e inteligível.
                                </p>
                            </div>
                            <div className='w-full sm:w-1/2 flex flex-col items-left gap-2'>
                                <img
                                    src={require('../assets/images/team-work.webp')}
                                    className='w-full rounded-lg'
                                    alt='Ad Value O Que Fazemos'
                                />
                            </div>
                        </div>
                    </div>
                    <div className='section px-8 sm:px-16 py-10 mx-auto'>
                        <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 mt-5'>
                            <div className='flex flex-col items-center gap-1'>
                                <img
                                    src={require('../assets/icons/mission.png')}
                                    className='w-16'
                                    alt='Ad Value Missão'
                                />
                                <p className='section-option-title'>Missão</p>
                                <p className='section-option-subtitle text-center'>
                                    Transformar ideias em realidades impactantes através de soluções de comunicação estratégica inovadoras e eficazes, contribuindo
                                    para o sucesso dos nossos clientes e parceiros.
                                </p>
                            </div>
                            <div className='flex flex-col items-center gap-1'>
                                <img
                                    src={require('../assets/icons/online-marketing.png')}
                                    className='w-16'
                                    alt='Ad Value Visão'
                                />
                                <p className='section-option-title'>Visão</p>
                                <p className='section-option-subtitle text-center'>
                                    Ser referência em comunicação estratégica em Moçambique, reconhecida pela inovação, qualidade e eficácia das nossas soluções,
                                    bem como pelo impacto positivo nos negócios de nossos clientes.
                                </p>
                            </div>
                            <div className='flex flex-col items-center gap-1'>
                                <img
                                    src={require('../assets/icons/innovation.png')}
                                    className='w-16'
                                    alt='Ad Value Valores'
                                />
                                <p className='section-option-title'>Valores</p>
                                <p className='section-option-subtitle text-center'>
                                    Buscamos constantemente soluções criativas e modernas para os desafios de comunicação.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='section-company px-8 sm:px-16 py-10 mx-auto gap-8'>
                        <div className='section-2 flex flex-col justify-between gap-2'>
                            <p className='section-mid-title text-center'>A Nossa Equipa</p>
                            <p className='section-mid-subtitle text-center'>
                                A nossa equipa é formada por profissionais experientes e qualificados, com vasta experiência em comunicação estratégica, marketing, jornalismo,
                                relações públicas e publicidade. Temos uma profunda
                                compreensão da realidade moçambicana e capacidade para desenvolver e implementar soluções realistas, objetivas, personalizadas, eficazes e sustentáveis.
                            </p>
                            <div className='grid grid-cols-1 sm:grid-cols-4 gap-5 mt-5'>
                                <div className='flex flex-col items-center gap-1'>
                                    <FaLightbulb size={40} color='#fbbd00' />
                                    <p className='section-option-title t-white'>Inovação</p>
                                    <p className='section-option-subtitle text-center t-white'>
                                        Buscamos constantemente soluções criativas e modernas para os desafios de comunicação.
                                    </p>
                                </div>
                                <div className='flex flex-col items-center gap-1'>
                                    <FaCheck size={40} color='#fbbd00' />
                                    <p className='section-option-title t-white'>Excelência</p>
                                    <p className='section-option-subtitle text-center t-white'>
                                        Compromisso com a qualidade e a eficácia em tudo o que fazemos.
                                    </p>
                                </div>
                                <div className='flex flex-col items-center gap-1'>
                                    <FaSeedling size={40} color='#fbbd00' />
                                    <p className='section-option-title t-white'>Sustentabilidade</p>
                                    <p className='section-option-subtitle text-center t-white'>
                                        Desenvolvimento de estratégias eficazes e sustentáveis que geram valor a longo prazo.
                                    </p>
                                </div>
                                <div className='flex flex-col items-center gap-1'>
                                    <FaChartLine size={40} color='#fbbd00' />
                                    <p className='section-option-title t-white'>Resultados</p>
                                    <p className='section-option-subtitle text-center t-white'>
                                        Compromisso com a qualidade e a eficácia em tudo o que fazemos.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section px-8 sm:px-16 py-10 mx-auto'>
                        <div className='flex flex-col justify-between gap-2'>
                            <p className='section-title text-center'>Porquê Escolher a <span style={{ color: '#0846d1' }}>Ad Value</span>?</p>
                            <p className='section-text text-center'>
                                A Ad Value é uma empresa inovadora e dinâmica, que oferece soluções de comunicação estratégica personalizadas e eficazes, que agregam valor aos
                                negócios e projetos dos nossos clientes. Trabalhamos de forma criativa e diferenciada para servir nossos clientes e parceiros, oferecendo soluções à
                                medida e custo-efetivas que contribuem para o sucesso dos seus negócios.
                            </p>
                            <div className='grid grid-cols-1 sm:grid-cols-3 gap-5 mt-5'>
                                <div className='flex flex-col items-center gap-1'>
                                    <MdVisibility size={40} color='#0846d1' />
                                    <p className='section-option-title'>Visibilidade</p>
                                    <p className='section-option-subtitle text-center'>
                                        Desenvolvemos e implementamos estratégias de comunicação e imagem que aumentam a visibilidade e a notoriedade dos nossos clientes.
                                    </p>
                                </div>
                                <div className='flex flex-col items-center gap-1'>
                                    <MdTrendingUp size={40} color='#0846d1' />
                                    <p className='section-option-title'>Crescimento</p>
                                    <p className='section-option-subtitle text-center'>
                                        Contribuímos para o crescimento e o sucesso dos negócios dos nossos clientes, através de soluções de comunicação estratégica eficazes.
                                    </p>
                                </div>
                                <div className='flex flex-col items-center gap-1'>
                                    <MdLocationPin size={40} color='#0846d1' />
                                    <p className='section-option-title'>Relevância</p>
                                    <p className='section-option-subtitle text-center'>
                                        Desenvolvemos estratégias de comunicação e imagem que tornam os nossos clientes mais relevantes e competitivos no mercado.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='section-contact px-8 sm:px-16 py-10 mx-auto'>
                        <div className='section-2 flex flex-col sm:flex-row justify-between gap-8 sm:gap-16'>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <p className='section-mid-title'>Quer uma solução convergente feita à medida da sua empresa?</p>
                                <p className='section-mid-subtitle'>Entre em contacto connosco e descubra como podemos ajudar a sua empresa a crescer.</p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                >
                                    <Form.Item
                                        name="name"
                                        label="Nome de Contacto"
                                        rules={[{ required: true, message: 'Por favor insira o seu nome!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="company"
                                        label="Empresa"
                                        rules={[{ required: true, message: 'Por favor insira o nome da sua empresa!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaUsers className="site-form-item-icon" />}
                                            placeholder="Empresa"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Telefone"
                                        rules={[{ required: true, message: 'Por favor insira o seu número de telefone!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaPhone className="site-form-item-icon" />}
                                            placeholder="Telefone"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ required: true, message: 'Por favor insira o seu email!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaMailBulk className="site-form-item-icon" />}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="checkbox"
                                        labelCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Checkbox className='section-mid-subtitle'>Li e aceito a política de privacidade</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" className="login-form-button">
                                            Contactem-me
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default About;
