// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowRight, FaChartLine, FaCheck, FaEye, FaFacebook, FaInstagram, FaLightbulb, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearchLocation, FaSeedling, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdAddBusiness, MdAdsClick, MdDesignServices, MdLocationPin, MdMenu, MdMenuOpen, MdPublish, MdSettingsAccessibility, MdTrendingUp, MdVideoLibrary, MdVisibility } from 'react-icons/md';
import '../Components/css/home.css';
import '../Components/css/about.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';

const Service = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMenu = (rota) => {
        setMenu(!menu)
        if (rota.length > 0) { navigate(rota) }
    }


    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8' style={{ maxWidth: '1400px' }}>
                        <img
                            src={require('../assets/logo/add-value.png')}
                            className='w-32'
                            onClick={() => navigate('/')}
                            alt='Ad Value Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                        <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Home</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/servicos')}>Serviços</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p  onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                            
                            <Button className='press-button' onClick={() => window.open('https://www.oeconomico.com/')}>
                                Notícias
                            </Button>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner-service'>
                        <div className='banner-content px-8 sm:px-16 gap-3' style={{ maxWidth: '1400px' }}>
                            <h1 className='banner-title'>SERVIÇOS DA <span className='bt-bold'>AD VALUE</span></h1>
                            <p className='banner-subtitle mt-5'>Ampla oferta de serviços transversais a todo o portfólio de soluções.</p>
                            <div className='banner-button'>
                                <p onClick={() => navigate('/contactos')} className='bb-text'>Contacte-nos</p>
                            </div>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Home</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/servicos')}>Produtos</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section px-8 sm:px-16 py-10 mx-auto gap-8'>
                        <div className='card-service flex flex-col sm:flex-row justify-between gap-2 sm:gap-8 px-3 sm:px-8 py-7'>
                            <div className='w-full sm:w-1/2 gap-3 px-2 sm:pl-8 sm:pr-3 py-4 flex flex-col items-center'>
                                <MdSettingsAccessibility size={90} color='#fbbd00' />
                                <p className='section-mid-title t-black'>Soluções de Comunicação Estratégica</p>
                                <p className='section-mid-subtitle t-mid-black'>
                                    Atuamos desde a criação de estratégias de comunicação à execução completa dos projetos, com foco na eficácia e inovação.
                                </p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-5 px-4 sm:pl-2 sm:pr-8 py-4 flex flex-col items-left'>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Comunicação Institucional Estratégica</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Desenvolvimento e implementação de estratégias de comunicação e imagem para instituições públicas e privadas, empresas e ONGs.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Gestão de Crise</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Análise, definição e implementação de estratégias para prevenir e minimizar impactos negativos.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Assessoria de Imprensa</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Elaboração e implementação de planos de comunicação estratégica, gestão de relacionamento com a imprensa e media training.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='card-service flex flex-col sm:flex-row justify-between gap-2 sm:gap-8 px-3 sm:px-8 py-7'>
                            <div className='w-full sm:w-1/2 gap-3 px-2 sm:pl-8 sm:pr-3 py-4 flex flex-col items-center'>
                                <MdVideoLibrary size={90} color='#fbbd00' />
                                <p className='section-mid-title t-black'>Soluções de Marketing e Publicidade</p>
                                <p className='section-mid-subtitle t-mid-black'>
                                    Desenvolvemos estratégias de marketing e publicidade para fortalecer a presença das marcas no mercado, utilizando as mais modernas
                                    ferramentas e abordagens.
                                </p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-5 px-4 sm:pl-2 sm:pr-8 py-4 flex flex-col items-left'>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Marketing Online/Digital e Novos Medias</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Estruturação e desenvolvimento de plataformas web, gestão de redes sociais e estratégias de e-mail marketing.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Publicidade</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Criação e produção de anúncios para todos os ambientes de mídia, soluções contemporâneas para publicidade tradicional e digital.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Conteúdos Audiovisuais</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Criação e produção de vídeos institucionais, documentários e coberturas de eventos, elaboração de guião, captação de imagens, edição e pós-produção.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='card-service flex flex-col sm:flex-row justify-between gap-2 sm:gap-8 px-3 sm:px-8 py-7'>
                            <div className='w-full sm:w-1/2 gap-3 px-2 sm:pl-8 sm:pr-3 py-4 flex flex-col items-center'>
                                <MdDesignServices size={90} color='#fbbd00' />
                                <p className='section-mid-title t-black'>Soluções de Conteúdos e Projetos Gráficos</p>
                                <p className='section-mid-subtitle t-mid-black'>
                                    Oferecemos serviços completos de produção de conteúdos informativos e promocionais, bem como a concepção de materiais gráficos de alta qualidade.
                                </p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-5 px-4 sm:pl-2 sm:pr-8 py-4 flex flex-col items-left'>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Produção de Conteúdos Informativos e Promocionais</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Desenvolvimento de conteúdos informativos de acordo com os objetivos de comunicação, soluções de impressão em alta qualidade.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Projetos Editoriais e Gráficos</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Design gráfico de brochuras, desdobráveis, revistas, jornais e newsletters, transformação de ideias em produtos finais marcantes e
                                        competitivos.
                                    </p>
                                </div>
                                <div className='flex flex-col w-full gap-2'>
                                    <p className='section-mid-title t-black-2'>Relações Públicas Estratégicas e Eventos</p>
                                    <p className='section-mid-subtitle t-mid-black-2 pl-4'>
                                        Criação e gestão de eventos, lobbying e advocacia de causas, sbordagens dirigidas para a concretização de objetivos institucionais e
                                        de negócios.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section-contact px-8 sm:px-16 py-10 mx-auto'>
                        <div className='section-2 flex flex-col sm:flex-row justify-between gap-8 sm:gap-16'>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <p className='section-mid-title'>Quer uma solução convergente feita à medida da sua empresa?</p>
                                <p className='section-mid-subtitle'>Entre em contacto connosco e descubra como podemos ajudar a sua empresa a crescer.</p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                >
                                    <Form.Item
                                        name="name"
                                        label="Nome de Contacto"
                                        rules={[{ required: true, message: 'Por favor insira o seu nome!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="company"
                                        label="Empresa"
                                        rules={[{ required: true, message: 'Por favor insira o nome da sua empresa!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaUsers className="site-form-item-icon" />}
                                            placeholder="Empresa"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Telefone"
                                        rules={[{ required: true, message: 'Por favor insira o seu número de telefone!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaPhone className="site-form-item-icon" />}
                                            placeholder="Telefone"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ required: true, message: 'Por favor insira o seu email!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaMailBulk className="site-form-item-icon" />}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="checkbox"
                                        labelCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Checkbox className='section-mid-subtitle'>Li e aceito a política de privacidade</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" className="login-form-button">
                                            Contactem-me
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Service;
