// src/Login.js
import axios from 'axios';
import 'antd/dist/reset.css';
import React, { useEffect } from 'react';
import { Form, Input, Button, notification, Divider, Checkbox } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { CSSTransition } from 'react-transition-group';
import { FaArrowRight, FaEye, FaFacebook, FaInstagram, FaLocationArrow, FaMailBulk, FaMailchimp, FaPhone, FaPlus, FaRegArrowAltCircleRight, FaSearchLocation, FaTwitter, FaUsers } from 'react-icons/fa';
import { MdLocationPin, MdMenu, MdMenuOpen, MdTrendingUp, MdVisibility } from 'react-icons/md';
import '../Components/css/home.css';
import '../Components/css/mobile.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Home = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)
    const [menu, setMenu] = React.useState(false)

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleMenu = (rota) => {
        setMenu(!menu)
        if (rota.length > 0) { navigate(rota) }
    }

    return (
        <CSSTransition in={true} appear={true} timeout={300} classNames="fade">
            <div className="flex flex-col h-full page items-center" id='home'>
                <Helmet>
                    <title>Ad Value</title>
                    <meta name="description" content="Ad Value - Comunicação que gera confiança" />
                    <meta name="keywords" content="Ad Value, Comunicação, Marketing, Assessoria de Imprensa, oeconomico.com" />
                    <meta charset="UTF-8" />
                    <meta name="author" content="Codeware Solutions" />
                    <meta property="og:title" content="Ad Value" />
                    <meta property="og:description" content="Ad Value - Comunicação que gera confiança" />
                    <meta property="og:image" content={require('../assets/logo/add-value.png')} />
                </Helmet>
                <div className="navbar w-full flex flex-row justify-between items-center">
                    <div className='flex flex-row items-center w-full justify-between mx-auto px-8' style={{ maxWidth: '1400px' }}>
                        <img
                            onClick={() => navigate('/')}
                            src={require('../assets/logo/add-value.png')}
                            className='w-32'
                            alt='Ad Value Logo'
                        />
                        <div className="flex flex-row items-center gap-14 menu-bar">
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/')}>Home</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/servicos')}>Serviços</p>
                            </p>
                            <p className='text-menu m-0'>
                                <p onClick={() => navigate('/contactos')}>Contactos</p>
                            </p>
                            <Button className='press-button' onClick={() => window.open('https://www.oeconomico.com/')}>
                                Notícias
                            </Button>
                        </div>
                        {menu ?
                            <MdMenuOpen onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />
                            : <MdMenu onClick={handleMenu} size={30} color='#fbbd00' className='block sm:hidden menu-icon menu-bar-icon' />}
                    </div>
                </div>

                <div className='relative w-full main-page'>
                    <div className='banner'>
                        <div className='banner-content px-8 sm:px-16' style={{ maxWidth: '1400px' }}>
                            <h1 className='banner-title'>COMUNICAÇÃO QUE GERA <p className='bt-bold'>CONFIANÇA</p></h1>
                            <p className='banner-subtitle'>A Ad Value Transforma Ideias em Realidades Impactantes</p>
                            <div className='banner-button' onClick={() => navigate('/servicos')}>
                                <p className='bb-text'>Mais informações</p>
                            </div>
                        </div>
                    </div>
                    {menu &&
                        <div className='menu-bar-mobile  block sm:hidden'>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/')}>Home</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/sobre-nos')}>Sobre nós</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/servicos')}>Produtos</p>
                            </p>
                            <p className='text-menu'>
                                <FaArrowRight size={17} color='#fbbd00' />
                                <p onClick={() => handleMenu('/contactos')}>Contactos</p>
                            </p>
                        </div>
                    }
                    <div className='section px-8 sm:px-16 py-10 mx-auto'>
                        <p className='section-title' id='about'>É assim que fazemos</p>
                        <div className='grid grid-cols-1 sm:grid-cols-4 gap-5 mt-5'>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/global-network.png')}
                                    className='w-16'
                                    alt='Ad Value Comunicação Integral'
                                />
                                <p className='section-option-title'>Comunicação Integral</p>
                                <p className='section-option-subtitle'>
                                    Oferecemos soluções completas de comunicação estratégica, marketing e assessoria de imprensa, garantindo
                                    conveniência e eficiência com um único fornecedor.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/business.png')}
                                    className='w-16'
                                    alt='Ad Value Tranquilidade na Comunicação'
                                />
                                <p className='section-option-title'>Tranquilidade na Comunicação</p>
                                <p className='section-option-subtitle'>
                                    Transformamos a comunicação da sua empresa com serviços inovadores, seguros e globais, ajudando a crescer e se
                                    destacar no mercado.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/controller.png')}
                                    className='w-16'
                                    alt='Ad Value Personalização'
                                />
                                <p className='section-option-title'>Personalização</p>
                                <p className='section-option-subtitle'>
                                    Você decide como trabalhamos juntos. Oferecemos serviços personalizados, administração e suporte contínuos, bem como
                                    um painel de controle para a gestão simplificada de todas as suas soluções de comunicação.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/idea.png')}
                                    className='w-16'
                                    alt='Ad Value Inovação'
                                />
                                <p className='section-option-title'>Inovação</p>
                                <p className='section-option-subtitle'>
                                    Com anos de experiência no setor de comunicação e marketing, combinamos conhecimento profundo e inovação para oferecer
                                    os melhores serviços a nossos clientes.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='section-contact px-8 sm:px-16 py-10 mx-auto'>
                        <div className='section-2 flex flex-col sm:flex-row justify-between gap-8 sm:gap-16'>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <p className='section-mid-title'>Quer uma solução convergente feita à medida da sua empresa?</p>
                                <p className='section-mid-subtitle'>Entre em contacto connosco e descubra como podemos ajudar a sua empresa a crescer.</p>
                            </div>
                            <div className='w-full sm:w-1/2 gap-2 flex flex-col'>
                                <Form
                                    name="normal_login"
                                    className="login-form"
                                    initialValues={{ remember: true }}
                                >
                                    <Form.Item
                                        name="name"
                                        label="Nome de Contacto"
                                        rules={[{ required: true, message: 'Por favor insira o seu nome!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<UserOutlined className="site-form-item-icon" />}
                                            placeholder="Nome"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="company"
                                        label="Empresa"
                                        rules={[{ required: true, message: 'Por favor insira o nome da sua empresa!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaUsers className="site-form-item-icon" />}
                                            placeholder="Empresa"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        label="Telefone"
                                        rules={[{ required: true, message: 'Por favor insira o seu número de telefone!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input
                                            prefix={<FaPhone className="site-form-item-icon" />}
                                            placeholder="Telefone"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        rules={[{ required: true, message: 'Por favor insira o seu email!' }]}
                                        labelCol={{ span: 24 }}
                                    >
                                        <input

                                            prefix={<FaMailBulk className="site-form-item-icon" />}
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="checkbox"
                                        labelCol={{ span: 24 }}
                                        required={true}
                                    >
                                        <Checkbox className='section-mid-subtitle'>Li e aceito a política de privacidade</Checkbox>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button htmlType="submit" className="login-form-button">
                                            Contactem-me
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                    <div className='section px-8 sm:px-16 py-10 mx-auto'>
                        <p className='section-title' id='about'>Serviços</p>
                        <p className='section-title-mid w-full sm:w-1/2 text-center'>
                            Comunicação estratégica, marketing digital, produção de conteúdos e assessoria de imprensa para transformar suas ideias em resultados impactantes.
                        </p>
                        <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 my-5'>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/message.png')}
                                    className='w-16'
                                    alt='Ad Value Comunicação Institucional Estratégica'
                                />
                                <p className='section-option-title'>Comunicação Institucional Estratégica</p>
                                <p className='section-option-subtitle'>
                                    Desenvolvemos estratégias de comunicação personalizadas para instituições públicas e privadas, alinhadas com os objetivos organizacionais, garantindo
                                    eficácia e impacto positivo.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/promotion.png')}
                                    className='w-16'
                                    alt='Ad Value Marketing Online/Digital e Novos Medias'
                                />
                                <p className='section-option-title'>Marketing Online/Digital e Novos Medias</p>
                                <p className='section-option-subtitle'>
                                    Criamos e implementamos plataformas web e estratégias de marketing digital, otimizando a presença online das marcas e acompanhando as tendências do mercado
                                    para maximizar o alcance e engajamento.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/adversting.png')}
                                    className='w-16'
                                    alt='Ad Value Produção de Conteúdos Informativos e Promocionais'
                                />
                                <p className='section-option-title'>Produção de Conteúdos Informativos e Promocionais</p>
                                <p className='section-option-subtitle'>
                                    Produzimos conteúdos escritos e audiovisuais de alta qualidade para diversos meios de comunicação, como TV, jornais, revistas, web e redes sociais,
                                    promovendo a imagem e os produtos de nossos clientes.
                                </p>
                            </div>
                            <div className='flex flex-col items-center sm:items-left gap-1'>
                                <img
                                    src={require('../assets/icons/touch.png')}
                                    className='w-16'
                                    alt='Ad Value Assessoria de Imprensa'
                                />
                                <p className='section-option-title'>Assessoria de Imprensa</p>
                                <p className='section-option-subtitle'>
                                    Gerenciamos a comunicação com a mídia, aumentando a visibilidade e notoriedade da sua empresa através de conteúdos relevantes, bem estruturados e
                                    estratégias eficazes de relações públicas.
                                </p>
                            </div>
                        </div>
                        <Button className="button">
                            <FaPlus size={14} />
                            Mais informações
                        </Button>
                    </div>
                    <div className='section-gr px-8 sm:px-16 py-10 mx-auto'>
                        <div className='section-in flex-col sm:flex-row gap-18'>
                            <div className='w-full sm:w-1/3 flex flex-col items-left gap-2'>
                                <img
                                    src={require('../assets/images/banner-press.webp')}
                                    className='w-full rounded-lg'
                                    alt='Ad Value O Económico'
                                />
                            </div>
                            <div className='w-full sm:w-2/3 flex flex-col items-left gap-2 py-8 my-auto'>
                                <p className='section-title'>O ECONÓMICO</p>
                                <p className='section-text'>
                                    O Económico, uma iniciativa da Ad Value, assegura a sua eficácia mediante a consolidação de uma marca única e
                                    distinta, cujo valor reside na sua capacidade de gerar e disseminar conteúdos informativos e formativos de especialidade económica. Estes
                                    conteúdos traduzem-se em mais-valias para quem os recebe, acompanha e absorve, através dos diversos meios do projeto.
                                </p>
                                <p className='section-text'>
                                    O Económico apresenta valências importantes para os objetivos institucionais e de negócios das empresas, oferecendo informações precisas e
                                    análises detalhadas que auxiliam na tomada de decisões estratégicas. Com uma abordagem focada em economia, o jornal torna-se uma ferramenta
                                    indispensável para profissionais e empresas que buscam manter-se atualizados e informados sobre as tendências e mudanças no mercado.
                                </p>
                                <Button className="button w-auto">
                                    <p className='m-0' onClick={() => window.open('https://www.oeconomico.com/')} target='_blank'>Visitar oeconomico.com</p>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='footer'>
                        <div className='footer-content'>
                            <div className='flex flex-col sm:flex-row gap-8 sm:gap-16 w-full'>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Links Rápidos</p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/')}>Home</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/sobre-nos')}>Sobre nós</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/servicos')}>Serviços</p>
                                    </p>
                                    <p className='footer-text'>
                                        <p onClick={() => navigate('/contactos')}>Contactos</p>
                                    </p>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <p className='footer-subtitle'>Contactos</p>
                                    <p className='footer-text'>
                                        <FaPhone size={17} color='white' />
                                        +258 86-075-8877
                                    </p>
                                    <p className='footer-text'>
                                        <FaMailBulk size={17} color='white' />
                                        oeconomicomz@gmail.com
                                    </p>
                                    <p className='footer-text'>
                                        <FaLocationArrow size={17} color='white' />
                                        Moçambique, Maputo
                                    </p>
                                </div>
                                <div className='footer-newsletter'>
                                    <p className='footer-subtitle'>Junta-te à nossa Newsletter</p>
                                    <div className='flex flex-row bg-white rounded-lg'>
                                        <input className='newsletter-input' placeholder='Insira o seu email' />
                                        <div className='button-about w-1/2'>
                                            <p className='mid-button-title'>SUBSCREVER</p>
                                        </div>
                                    </div>
                                    <p className='footer-text mt-5'>
                                        *Subscreva a nossa newsletter para receber as últimas novidades e promoções.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='footer-bottom mt-10'>
                            <p className='text-white text-xs'>
                                © 2024 <span>Codeware Solutions</span>. Todos os direitos reservados.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </CSSTransition>
    );
};

export default Home;
