import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {QueryClient, QueryClientProvider} from 'react-query';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './Pages/home';
import About from './Pages/about';
import Service from './Pages/service';
import Contact from './Pages/contact';
import MainLayout from './Layouts/MainLayout';

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children:[
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/sobre-nos',
        element: <About />,
      },
      {
        path: '/servicos',
        element: <Service />,
      },
      {
        path: '/contactos',
        element: <Contact />,
      }
    ]
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router}/>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
